import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { Deal, DealContact } from '../models/deal.model';
import { lAddress } from '../models/lead.model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DealService {

  public view: 'list'

  deals: IApi<Deal>;
  dealsExcel: IApi<Deal>;
  dealContact: IApi<DealContact>;
  dealAddresses: IApi<lAddress>;
  constructor(private http: HttpClient) {
    this.deals = new GenericApi<Deal>('deals', http);
    this.dealContact = new GenericApi<DealContact>('dealContacts', http);
    this.dealAddresses = new GenericApi<lAddress>('dealAddresses', http);
    this.dealsExcel = new GenericApi<lAddress>('deals/create/excel', http);
  }

  createMultipleAddress({ dealId, addresses }): Promise<any> {
    return this.dealAddresses.create({ dealId, addresses } as any, 'add/multiple').then(d => {
      return d;
    }).catch(err => {
      return err;
    })
  }
  downloadDealExcel(): Observable<Blob> {
    const url = `${environment.apiUrls.api}/api/deals/create/excel`;
     const token = localStorage.getItem('token') || '';


        const headers = new HttpHeaders({
          'x-access-token': token,
        });
    return this.http.get(url, {headers, responseType: 'blob' });
  }


}
