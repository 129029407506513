import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { Lead, lAddress } from '../models/lead.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  public view: 'list';
  leads: IApi<Lead>;
  leads1: IApi<Lead>;
  leads2: IApi<Lead>;
  leads3: IApi<Lead>;
  leads4: IApi<Lead>;
  leadAddresses: IApi<lAddress>;
  leadService: IApi<any>;
  deleteAllLeads: IApi<Lead>
  leadFollowUps: IApi<Lead>
  followUpPurposes: IApi<Lead>
  successfulFollowUps: IApi<Lead>
  leadExcel: IApi<Lead>



  constructor(private http: HttpClient) {
    this.leads = new GenericApi<Lead>('leads', http);
    this.leads1 = new GenericApi<Lead>('leads', http);
    this.leads2 = new GenericApi<Lead>('leads', http);
    this.leads3 = new GenericApi<Lead>('leads', http);
    this.leads4 = new GenericApi<Lead>('leads', http);
    this.leadAddresses = new GenericApi<lAddress>('leadAddresses', http);
    this.leadService = new GenericApi<any>('leadServices', http);
    this.deleteAllLeads = new GenericApi<Lead>('leads/delete/all', this.http)
    this.leadFollowUps = new GenericApi<Lead>('leadFollowUps', this.http)
    this.followUpPurposes = new GenericApi<Lead>('followUpPurposes', this.http)
    this.successfulFollowUps = new GenericApi<Lead>('successfulFollowUps', this.http)
    this.leadExcel = new GenericApi<Lead>('leads/create/excel', this.http)
  }

  downloadLeadExcel(): Observable<Blob> {
    const url = `${environment.apiUrls.api}/api/leads/create/excel`;
    const token = localStorage.getItem('token') || '';


    const headers = new HttpHeaders({
      'x-access-token': token,
    });
    return this.http.get(url, {headers, responseType: 'blob' });
  }


}
